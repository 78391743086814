import {PlatformAPIHttpClient} from "./platform-api-http-client";

export class CampaignsHttpClient extends PlatformAPIHttpClient {
    public createCampaign = (name: string, segmentBudget: number, segmentMaxCPM: number, segmentKeywords: string[],
                             segmentCategories: number[]) => this.instance.post('/campaigns/create', {
        name: name,
        segment_budget: segmentBudget,
        segment_max_cpm: segmentMaxCPM,
        segment_keywords: segmentKeywords,
        segment_categories: segmentCategories
    });

    public getCampaignsList = (showImpressions: boolean) => this.instance.get('/campaigns/list', {
        params: {
            show_impressions: showImpressions
        }
    });

    public getCampaignInfo = (campaignId: string) => this.instance.get(`/campaigns/view/${campaignId}`);
}