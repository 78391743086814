import styled, { css } from "styled-components";

interface InputProps {
    viewOnly: boolean;
}

export const Container = styled.div<InputProps>`
    position: relative;

    input {
        ${props => (props.viewOnly) && css`
            pointer-events: none;
        `}

        height: 100%;
        width: 100%;

        border: 1px solid #BFBFBF;
        padding: 0 20px;

        font-size: 14px;
        line-height: 16px;
        color: #575757;

        &:focus {
            outline: none;
            border: 1px solid #256EF3;
        }
    }
`;