import React, {useCallback, useEffect, useState} from 'react';
import { CampaignsHttpClient } from "../../services/api/campaigns-http-client";
import logoImg from '../../assets/imasters-logo.png';
import StartingSteps from '../../components/Campaigns/ListCampaigns/StartingSteps';
import DisplayCampaignsInfo from '../../components/Campaigns/ListCampaigns';
import { Container, Header } from './styles';
import {useHistory} from "react-router-dom";
import {ICampaignInfo} from "../../interfaces/Campaigns/ICampaignInfo";

const ListCampaigns: React.FC = () => {
  const [campaigns, setCampaigns] = useState<ICampaignInfo[]>([]);
  const history = useHistory();

  const getCampaignsAndRedirectIfNoCampaign = useCallback(() => {
      new CampaignsHttpClient().getCampaignsList(true)
          .then(function (response) {
              if (response.data.data.length === 0)
                  history.push('/create-campaigns');

              setCampaigns(response.data.data)
          });
  }, [setCampaigns, history]);

  useEffect(() => getCampaignsAndRedirectIfNoCampaign(), [setCampaigns, getCampaignsAndRedirectIfNoCampaign]);

  const someProcessingCampaign = (campaigns.some(campaign => campaign.state === 'processing'));

  return (
    <>
        <Header className='shadow-header'>
            <img src={logoImg} alt="iMastersAds Logo" />
            <div>
                <h1>Anunciante {'>'} Campanha</h1>
            </div>
        </Header>

        <Container className='centered-container'>
          <DisplayCampaignsInfo campaigns={campaigns} />

          {someProcessingCampaign && (
            <StartingSteps />
          )}

        </Container>
    </>
  );
}

export default ListCampaigns;