import React, {ReactNode} from "react";
import {ErrorBoxContainer} from "./styles";

interface ErrorBoxProps {
    showBox: boolean;
    setShowBox(state: boolean): void;
    children: ReactNode;
}

function ErrorBox({children, showBox, setShowBox}: ErrorBoxProps) {
    return (
            <ErrorBoxContainer showBox={showBox} className="error-box">
                {children}
                <button onClick={() => setShowBox(false)} type="button">&#10005;</button>
            </ErrorBoxContainer>
        )
}

export default ErrorBox;