import styled, {css} from "styled-components";

interface ErrorBoxProps {
    showBox: boolean;
}


export const ErrorBoxContainer = styled.div<ErrorBoxProps>`
  & {
    opacity: 1;
    background: #FF5656;
    padding: 20px;

    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    z-index: 999;

    ${props => !(props.showBox) && css`
      opacity: 0;
  `}
    
    button {
      color: #C4C4C4;
      background: none;
      border: none;

      position: absolute;
      top: 11px;
      right: 11px;

      font-size: 11px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;