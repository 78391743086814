import {useEffect} from "react";
import {UsersHttpClient} from "../api/users-http-client";
import {LoginHttpClient} from "../api/login-http-client";

interface IAppRoute {
    setLoading(newValue: boolean): void;
    setAuth(newValue: boolean): void;
}

export function AppRoute({setLoading, setAuth}: IAppRoute) {
    useEffect(() => {
        const fetchLogged = async () => {
            try {
                setLoading(true);
                const result = await new UsersHttpClient().getAuthenticatedUserInfo();
                if (result.status === 200)
                    setAuth(true);
            } catch (e) {
                setAuth(false);
                await new LoginHttpClient().logout(); // In case token expired
            }

            setLoading(false);
        }

        fetchLogged();
    }, [setLoading, setAuth]);
}

export default AppRoute;
