import {Redirect, Route, RouteProps} from "react-router-dom";
import React, {useState} from "react";
import AppRoute from "./AppRoute";

interface IPrivateRouteProps extends RouteProps {}

const PublicRoute: React.FC<IPrivateRouteProps> = ({...rest}) => {
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuth] = useState(false);

    AppRoute({setLoading, setAuth});

    if (isAuthenticated && !isLoading)
        return <Redirect to='/list-campaigns'/>
    else if (!isAuthenticated && !isLoading)
        return <Route {...rest} />
    else
        return <Route />
}

export default PublicRoute;