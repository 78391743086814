import React, {useState} from "react";
import {BaseContainer, ContainerAuth, Footer} from "./styles";
import {LoginHttpClient} from "../../services/api/login-http-client";
import Button from "../../components/Generic/Button";
import LoginInput from "../../components/Authenticate/LoginInput/";
import ErrorBox from "../../components/Generic/ErrorBox";
import {Link, useHistory} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import InfoCarrousel from "../../components/Authenticate/InfoCarrousel";

const Authenticate: React.FC = () => {
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [hasEmailInputError, setHasEmailInputError] = useState(false);
    const [hasPassInputError, setHasPassInputError] = useState(false);
    const [showErrorBox, setShowErrorBox] = useState(false);
    const history = useHistory();

    return(
        <BaseContainer>
            <ContainerAuth>
                <header>
                    <Link to="/"><img src="imasters-logo.png" alt="iMaster Ads Logo" /></Link>
                </header>
                <section>
                    <h1>Acesse sua conta</h1>
                    <p>Construa campanhas de mídia para audiências de tecnologia</p>
                    <label>Endereço de e-mail</label>
                    <LoginInput name="email" type="text" value={emailInput} setValue={setEmailInput}
                                hasError={hasEmailInputError} setHasError={setHasEmailInputError} />
                    <label>Senha</label> <Link className="default-link" to="/">Esqueceu a senha?</Link>
                    <LoginInput name="password" type="password" value={passwordInput} setValue={setPasswordInput}
                                hasError={hasPassInputError} setHasError={setHasPassInputError} />
                    <Button execute={() => authenticate()}>Acessar</Button>
                </section>
                <ErrorBox showBox={showErrorBox} setShowBox={setShowErrorBox}>
                    <span>Não encontramos uma conta com o e-mail e senha preenchidos. <br />
                        Verifique se os campos foram preenchidos corretamente e tente novamente.</span>
                </ErrorBox>

                <Footer>Powered by <span>Voxus</span></Footer>
            </ContainerAuth>
            <InfoCarrousel />
        </BaseContainer>
    )

    function authenticate() {
        if(hasEmailInputError || hasPassInputError) {
            setShowErrorBox(true);
            return;
        }

        new LoginHttpClient().authenticate(emailInput, passwordInput)
            .then(function (response) {
                history.push('/list-campaigns');
            })
            .catch(function (error) {
                setShowErrorBox(true);
                setHasPassInputError(true);
                setHasEmailInputError(true);
            });
    }
}

export default Authenticate;