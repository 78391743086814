import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    body, html {
      font-family: 'Work Sans', sans-serif;
      background: #EFF1F5;
    }

    .white {
      background: #FFF;
    }

    .default-input {
      flex: 1;

      height: 47px;
      max-width: 622px;

      border: 1px solid #BFBFBF;
      padding: 0 20px;

      font-size: 14px;
      line-height: 16px;

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #8E8E8E;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #8E8E8E;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #8E8E8E;
      }

      &:focus {
        outline: none;
        border: 1px solid #256EF3;
      }
    }

    .default-link {
      color: #256EF3;
      font-size: 14px;
      color: #256EF3;
      text-decoration: none;

      cursor: pointer;
      background: none;
      border: none;

      &:active {
        color: #256EF3
      }

      &:visited {
        color: #256EF3
      }

      &:hover {
        color: #256EF3
      }

      &:link {
        color: #256EF3
      }
    }
    
    .centered-container {
      max-width: 867px;
      margin: 0 auto;
      padding-top: 90px;
    }

    .shadow-header {
      box-shadow: 0px 4px 10px #D1D9E7;
      background: #FFF;
    }

    .card-shadow {
      box-shadow: -2px 4px 10px #D1D9E7, 2px 4px 10px #D1D9E7;
      border-radius: 10px;
      background: #FFF;
    }
`;