import {useContext, createContext, ReactNode, useState} from 'react';

interface CampaignValuesProviderProps {
    children: ReactNode;
}

interface CampaignValuesContextData {
    campaignName: string;
    setCampaignName(value: string): void;
    keyword1: string;
    setKeyword1(value: string): void;
    keyword2: string;
    setKeyword2(value: string): void;
    keyword3: string;
    setKeyword3(value: string): void;
    keyword4: string;
    setKeyword4(value: string): void;
    budget: number;
    setBudget(value: number): void;
    cpmMax: number;
    setCpmMax(value: number): void;
    categoriesIDs: number[];
    setCategoriesIDs(value: number[]): void;
}

export const CampaignValuesContext = createContext<CampaignValuesContextData>({} as CampaignValuesContextData);


export function CampaignValuesProvider({ children }: CampaignValuesProviderProps){
    const [campaignName, setCampaignName] = useState('');
    const [keyword1, setKeyword1] = useState('');
    const [keyword2, setKeyword2] = useState('');
    const [keyword3, setKeyword3] = useState('');
    const [keyword4, setKeyword4] = useState('');
    const [budget, setBudget] = useState(0);
    const [cpmMax, setCpmMax] = useState(0);
    const [categoriesIDs, setCategoriesIDs] = useState<number[]>([]);
   

    return(
        <CampaignValuesContext.Provider 
            value={ 
                {
                    campaignName, setCampaignName,
                    keyword1, setKeyword1,
                    keyword2, setKeyword2,
                    keyword3, setKeyword3,
                    keyword4, setKeyword4,
                    budget, setBudget,
                    cpmMax, setCpmMax,
                    categoriesIDs, setCategoriesIDs
                } 
            }
        >
            { children }
        </CampaignValuesContext.Provider>
    )
    }



export function useNewCampaignValues() {
   const context = useContext(CampaignValuesContext);

   if(!context) {
       throw new Error('useNewCampaignValues must be used within an Provider');
  } 

   return context;
}