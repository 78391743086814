import styled, {css} from "styled-components";

interface LoginInputProps {
    hasError: boolean;
}

export const LoginInputContainer = styled.div<LoginInputProps>`
  input {
    color: #8E8E8E;
    width: 435px;
    height: 47px;
    margin-top: 9px;
    margin-bottom: 26px;

    ${props => (props.hasError) && css`
      border-color: #FF5656;
    `}
  }
`;