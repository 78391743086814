import styled from "styled-components";

export const Container = styled.div`
    margin: 60px 0 123px;
    padding-bottom: 42px;

    .title-div {
        border-bottom: 1px solid #EBEBEB;
    }

    h2 {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;

        color: #256EF3;

        padding: 40px 0 20px 60px;

    }
`;

export const CreativesData = styled.div`
    table {
        border-collapse: collapse;

        width: 100%;

        tr {
            padding: 0 60px;

            th, td {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #585858;
            }
            
            th {
                text-align: start;
                
                padding-top: 10px;
                padding-bottom: 10px;

                border-bottom: 1px solid #EBEBEB;

                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #585858;
                
            }
            
            td {
                padding: 20px 0;
                border-bottom: 1px solid #EBEBEB;
            }
            
            td:first-child, th:first-child {
                padding-left:60px;
            }
        }
    }
`;