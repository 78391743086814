import React, { useCallback, useState } from 'react';
import { Container } from './styles';

interface KeywordInputProps {
    value: string;
    viewOnly: boolean;
    setValue(value: string): void;
    placeholder?: string | undefined;
}

function KeywordInput({value, setValue, viewOnly, placeholder}: KeywordInputProps) {
    const validateInput = useCallback((newValue) => {
        if(viewOnly) return;

        setValue(newValue);

    }, [setValue, viewOnly]);

    return (
        <Container viewOnly={viewOnly}>
            <input type="text" onChange={(event) => validateInput(event.target.value)} value={value} placeholder={placeholder} />
        </Container>
    )
}

export default KeywordInput;