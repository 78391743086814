import React, { ReactNode } from "react";
import { SubmitButton } from './styles';

interface ButtonProps {
    execute(): void;
    children: ReactNode;
}

function Button({ execute, children }: ButtonProps) {
    return(
        <SubmitButton type="button" onClick={() => execute()}>{children}</SubmitButton>
    );
}

export default Button;