import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Container } from './styles';
import Categories from '../Campaigns/Generic/Categories';
import Tooltip from '../Generic/Tooltip';
import { useNewCampaignValues } from '../../pages/NewCampaign/NewCampaignContext';
import KeywordInput from '../Campaigns/Generic/KeywordInput';
import { useLocation } from 'react-router';
import {IAudienceDetailProps} from "../../interfaces/Campaigns/CampaignDetails/IAudienceDetailProps";

const AudienceDetail: React.FC<IAudienceDetailProps> = ({ keywords , categories }: IAudienceDetailProps) => {

    const { 
        keyword1, setKeyword1,
        keyword2, setKeyword2,
        keyword3, setKeyword3,
        keyword4, setKeyword4 
    } = useNewCampaignValues();

    const location = useLocation().pathname;
    
    const viewOnly = useMemo(() => {
      return location.startsWith('/campaign-details');
    }, [location]);
    
    return (
        <Container className='card-shadow'>
            <h2>Audiência</h2>
            <div className="categorias">
                <span>Categorias:</span>
                <Scrollbars style={{ width: 667, height: 450 }}>
                  <Categories viewOnly={viewOnly} viewOnlyCategories={categories?.map(category => category.category)} />
                </Scrollbars>
            </div>
            <div className="keywords">
                <div className="keywords-description">
                    <div>
                        <span>Keywords: <Tooltip content='Recomendamos 1 keyword ou expressões por box, sobre o que seu público possa gostar, separando as palavras com "-" (hífen)'/></span> 
                    </div>
                    <p>Selecione até 4 keywords para conteúdos gerais (ex. como-fazer)</p>
                </div>
                <div className="keywords-inputs">
                    <KeywordInput value={keyword1} setValue={setKeyword1} viewOnly={viewOnly} placeholder={keywords ? keywords[0]?.keyword : ''} />
                    <KeywordInput value={keyword2} setValue={setKeyword2} viewOnly={viewOnly} placeholder={keywords ? keywords[1]?.keyword : ''} />
                    <KeywordInput value={keyword3} setValue={setKeyword3} viewOnly={viewOnly} placeholder={keywords ? keywords[2]?.keyword : ''} />
                    <KeywordInput value={keyword4} setValue={setKeyword4} viewOnly={viewOnly} placeholder={keywords ? keywords[3]?.keyword : ''} />
                </div>
            </div>
        </Container>
    );
}

export default AudienceDetail;