import styled from "styled-components";

export const Content = styled.span`
    background: #585858;
    color: #FFF !important;
    font-size: 12px !important;
    line-height: 16px !important;

    padding: 15px;

    position: absolute;
    bottom: 26px;
    left: -216px;
    max-width: 232px;

    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;
`;

export const Container = styled.span`
    display: inline-block;
    position: relative;

    svg {
        margin-left: 6px;
        color: #FFF;
        fill: #8E8E8E;
        
    }
    
    &:hover ${Content} {
        visibility: visible;
        opacity: 1;
    }
        
`;