import React, { useEffect, useState } from 'react';
import Category from './Category';
import { Container } from './styles';
import {SellersHttpClient} from "../../../../services/api/sellers-http-client";
import {ICategoryInfo} from "../../../../interfaces/Campaigns/Segments/ICategoryInfo";

interface CategoriesProps {
  viewOnly: boolean;
  viewOnlyCategories?: ICategoryInfo[];
}

function Categories({viewOnly, viewOnlyCategories}: CategoriesProps) {
  const [categories, setCategories] = useState<ICategoryInfo[]>([]);

  useEffect(() => {
    if (viewOnly && viewOnlyCategories) {
      return setCategories(viewOnlyCategories);
    }
    new SellersHttpClient().getSiteCategories().then(response => setCategories(response.data.data));
  }, [viewOnly, viewOnlyCategories]);

  return (
    <Container>
        {categories.map(category => (
          <Category key={category.id} title={category.name} description={category.description} id={category.id} viewOnly={viewOnly}/>
        ))}
    </Container>
  );
}

export default Categories;