import styled from "styled-components";

export const Header = styled.div`
    padding: 29px 152px 29px 65px;

    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #585858;

    display: flex;
    justify-content: space-between;

    .left-side {
        a {
            margin-right: 46px;
    
            text-decoration: none;
            color: #585858;
        }
    }

    .right-side {
        .state-span {
            margin-right: 21.6875rem;
        }
    }
`;

export const Container = styled.div`
    
`;