import styled, {css} from "styled-components";

interface inputInfo{
    isFocused: boolean;
    isErrored: boolean;
    viewOnly: boolean;
    errorMessage: string;
}

export const Container = styled.div<inputInfo>`
    border: 1px solid #BFBFBF;
    
    ${props => props.isFocused && css`
        border: 1px solid #256EF3;
    `}

    ${props => (props.isErrored) && css`
        border: 1px solid #FF5656;
    `}

    height: 47px;
    max-width: 587px;

    padding: 14px 20px;
    
    span {
        color: #BEBEBE;
    }
    
    input {
        ${props => (props.viewOnly) && css`
            pointer-events: none;
        `}

        border: none;
        width: 100%;

        padding: 0 20px;

        font-size: 14px;
        line-height: 16px;
        color: #575757;

        &:focus {
            outline: none;
            border: none;
        }

    }

    position: relative;

    &::after {
        content: '${props => props.errorMessage}';
        color: #FF5656;
        font-size: 12px;
        line-height: 14px;

        position: absolute;
        left: 0;
        bottom: -16px;

        ${props => !(props.isErrored) && css`
            display: none;
        `}
    }
`;