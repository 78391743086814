import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: inline-block;

    .error-box div {
        position: absolute;
        bottom: 75%;
        left: 85%;
        width: 521px;

        button {
            margin: 0;
        }
    }
`;