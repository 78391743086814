import React from "react";

interface ICarrouselImage {
    src: string;
    alt: string;
    title: string
    text: string;
}

function CarrouselImage ({src, alt, title, text}: ICarrouselImage) {
    const handleDragStart = (e: any) => e.preventDefault();

    return (
        <>
            <div className="img-carrousel">
                <img src={src} alt={alt} onDragStart={handleDragStart} role="presentation" />
            </div>
            <div className="text-carrousel">
                <h1 dangerouslySetInnerHTML={{__html: title}} />
                <p dangerouslySetInnerHTML={{__html: text}} />
            </div>
        </>
    )


}

export default CarrouselImage;