import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Container } from './styles';

interface ModalProps {
    closeModal(): void;
}

function ModalCampaignCreated({ closeModal }: ModalProps) {
    const history = useHistory();

    const handleCloseModal = useCallback(() => {
        closeModal();
        history.push('list-campaigns');
    }, [closeModal, history]);

    return (
        <Container >
            <div>
                <div className="title toggle">
                    <h1>Sua campanha foi criada!</h1>
                    <button type="button" onClick={() => handleCloseModal()}>&#10005;</button>
                </div>
                <div className="description">
                    <p>Nosso time já recebeu uma notificação para aprovar sua campanha internamente, iremos ativar sua campanha assim que validarmos que está tudo pronto.</p>
                    <p>Certifique-se de ter feito as configurações da sua tag e Google Analytics enquanto isso e avise nosso time através do seu contato via e-mail.</p>
                </div>
            </div>
        </Container>
    )
}

export default ModalCampaignCreated;