import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';
import InvestmentInput from '../InvestmentInput';
import { useNewCampaignValues } from '../../../../pages/NewCampaign/NewCampaignContext';
import Tooltip from '../../../Generic/Tooltip';
import {IInvestmentProps} from "../../../../interfaces/Campaigns/CampaignDetails/IInvestmentProps";

function InvestmentDetail({ viewOnlyBudget, viewOnlyCpmMax }: IInvestmentProps) {
    const { budget, setBudget, cpmMax, setCpmMax } = useNewCampaignValues();
    
    const location = useLocation().pathname;
    
    const viewOnly = useMemo(() => {
      return location.startsWith('/campaign-details');
    }, [location]);

    return (
        <Container className='card-shadow'>
            <h2>Investimento</h2>
            <div className="border-top">
                <span className="help-span">Budget Mensal: <Tooltip content='Valor do budget que estará disponível para consumo da campanha durante o período de um mês. No início do mês seguinte, o saldo para consumo do budget é iniciado novamente.'/></span>
                <InvestmentInput value={budget} setValue={setBudget} viewOnly={viewOnly} placeholder={viewOnlyBudget} />
            </div>
            <div>
                <span className="help-span">CPM máx: <Tooltip content='Nossos algoritmos otimizarão a campanha para um bid que gere o máximo de impressões possíveis, garantindo que o bid máximo fique no valor definido por você'/></span>
                <InvestmentInput value={cpmMax} setValue={setCpmMax} viewOnly={viewOnly} placeholder={viewOnlyCpmMax} />
            </div>
        </Container>
    );
}

export default InvestmentDetail;