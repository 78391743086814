import React from "react";
import { FiHelpCircle } from 'react-icons/fi';
import { Container, Content } from './styles';

interface ToolTipProps {
    content: string;
}

function Tooltip({ content }: ToolTipProps) {
    return (
        <Container>
            <FiHelpCircle size={16} />
            <Content>{content}</Content>
        </Container>
    )
}


export default Tooltip;