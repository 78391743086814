import React, {useCallback, useEffect, useState} from 'react';
import GeneralDetail from '../../components/Campaigns/CreateCampaign/GeneralDetail';
import AudienceDetail from '../../components/AudienceDetail';
import InvestmentDetail from '../../components/Campaigns/Generic/InvestmentDetail';
import { CampaignValuesProvider } from './NewCampaignContext';
import { Header, Container } from './styles';
import NewCampaignButton from '../../components/Campaigns/CreateCampaign/NewCampaignButton';
import ModalCampaignCreated from '../../components/Campaigns/CreateCampaign/ModalCampaignCreated';
import {CampaignsHttpClient} from "../../services/api/campaigns-http-client";
import {useHistory} from "react-router-dom";

const NovaCampanha: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const getCampaignsAndRedirectIfHasCampaign = useCallback(() => {
    new CampaignsHttpClient().getCampaignsList(false)
        .then(function (response) {
          if (response.data.data.length !== 0)
            history.push('/list-campaigns');
        });
  }, [history]);

  useEffect(() => getCampaignsAndRedirectIfHasCampaign(), [getCampaignsAndRedirectIfHasCampaign]);

    return (
    <CampaignValuesProvider>
      <Header className='shadow-header'>
        <h1>Nova Campanha</h1>
      </Header>

      <Container className="centered-container">
        <GeneralDetail />    

        <AudienceDetail />

        <InvestmentDetail />

        <NewCampaignButton setShowModal={setShowModal}>CONCLUIR</NewCampaignButton>

      </Container>

    {showModal && (
      <ModalCampaignCreated closeModal={() => setShowModal(false)} />
    )}

    </CampaignValuesProvider>
  );
}

export default NovaCampanha;