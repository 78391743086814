import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 60px;

    .title-div {
        border-bottom: 1px solid #EBEBEB;
    }

    h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #256EF3;

        max-width: 230px;
        border-bottom: 1px solid #256EF3;
        padding: 28px 70px;

    }
`;

export const CampaignData = styled.div`
    table {
        border-collapse: collapse;

        margin-top: 20px;
        width: 100%;

        tr {
            padding-left: 60px;

            th, td {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #585858;
            }
            
            th {
                text-align: start;
                
                padding-bottom: 10px;
                
            }
            
            td {
                padding: 20px 0;
                background: #F8F8F8;

                svg {
                    margin-right: 10px;
                }
            }
            
            td:first-child, th:first-child {
                padding-left:60px;
            }
        }
    }
`;