import { Container } from './styles';

interface ModalProps {
    closeModal(): void;
    image_url: string;
    image_description: string;
}

function ModalViewCreative({ closeModal, image_url, image_description }: ModalProps) {

    return (
        <Container >
            <div>
                <div className="title toggle">
                    <h1>Visualização do anúncio</h1>
                    <button type="button" onClick={() => closeModal()}>&#10005;</button>
                </div>
                <div className="creative-view">
                    <img src={image_url} alt={image_description} />
                </div>
            </div>
        </Container>
    )
}

export default ModalViewCreative;