import axios, {AxiosInstance, AxiosResponse} from 'axios';

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
    protected readonly instance: AxiosInstance;

    protected constructor(url: string) {
        this.instance = axios.create({
            baseURL: url,
            withCredentials: true
        });
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleResponse = ({ data }: AxiosResponse) => data;

    protected _handleError = (error: any) => Promise.reject(error); // Make _handleError send state of
    // unauthenticated on 401 response
}