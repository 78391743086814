import React from "react";
import Tooltip from "../../../Generic/Tooltip";
import { Container, Header, ContainerCheckEmailText, Main, Phrase, Steps } from './styles';

function StartingSteps() {
    return (
        <Container className='card-shadow'>
            <Header>
                <h2>Começando</h2>
            </Header>

            <Phrase>Complete todas as etapas para ativar uma campanha no iMastersAds</Phrase>

            <Main>
                <Steps>
                    <div className="step">
                        <div className='circle'>1</div>
                        <p className='cross-line'>Crie sua campanha</p>
                    </div>

                    <div className="step">
                        <div className='circle'>2</div>
                        <p>Instale sua tag via GTM <Tooltip content='Não serão coletados dados do seu site, a funcionalidade da tag é apenas para integrarmos com o seu Google Analytics para envio dos dados da campanha' /> </p>
                    </div>

                    <div className="step">
                        <div className='circle'>3</div>
                        <p>Configure sua vista do Google Analytics</p>
                    </div>

                    <div className="step">
                        <div className='circle'>4</div>
                        <p>Envie seus banners</p>
                    </div>
                </Steps>

                <ContainerCheckEmailText>
                    <h4>Confira seu e-mail</h4>
                    <p>As instruções sobre os passos</p>
                    <p>2, 3 e 4 foram enviadas para o</p> 
                    <p>seu e-mail, mas caso tenha</p> 
                    <p>dúvidas, consulte o nosso time.</p>
                </ContainerCheckEmailText>
            </Main>
        </Container>
    )
}

export default StartingSteps;