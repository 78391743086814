import React, { useCallback, useState } from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { Container, CreativesData } from './styles';
import {IListCampaignCreativesProps} from "../../../../interfaces/Creatives/IListCampaignCreativesProps";
import ModalViewCreative from '../ModalViewCreative';
import formatStatusString from '../../../../services/utils/formatStatusString';
import formatDate from '../../../../services/utils/formatDate';
import getStatusColor from '../../../../services/utils/getStatusColor';

const CampaignCreatives: React.FC<IListCampaignCreativesProps> = ({creatives}) => {
    const [showCreativeModal, setShowCreativeModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageDescription, setImageDescription] = useState('');

    const handleCreativeClick = useCallback((imageUrl, imageDescription) => {
        setImageUrl(imageUrl);
        setImageDescription(imageDescription);
        setShowCreativeModal(true);
    }, [setImageUrl, setImageDescription, setShowCreativeModal]);
    
    return (
        <Container className='card-shadow'>
            <div className='title-div'>
                <h2>Anúncios da Campanha</h2>
            </div>

            <CreativesData>
                <table>
                    <thead>
                        <tr>
                            <th>Criativo</th>
                            <th>Início</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {creatives?.map(creative => (
                            <tr key={creative.id}>
                                <td>
                                    <button className='default-link' onClick={() => handleCreativeClick(creative.creative_details.cdn_image_url, creative.creative_details.name)}>
                                        {creative.creative_details.name}
                                    </button>
                                </td>
                                <td>{formatDate(creative.start_date)}</td>
                                <td><BsFillCircleFill size={10} fill={getStatusColor(creative.state)} /> {formatStatusString(creative.state)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CreativesData>

            {showCreativeModal && (
                <ModalViewCreative  closeModal={() => setShowCreativeModal(false)} image_description={imageDescription} image_url={imageUrl} />
            )}
        </Container>
    );
}

export default CampaignCreatives;