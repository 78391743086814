import React, { useEffect, useState } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import AudienceDetail from "../../components/AudienceDetail";
import CampaignCreatives from "../../components/Campaigns/CampaignDetails/CampaignCreatives";
import InvestmentDetail from "../../components/Campaigns/Generic/InvestmentDetail";
import { CampaignsHttpClient } from "../../services/api/campaigns-http-client";
import { Header, Container } from './styles';
import {ICampaignDetailData} from "../../interfaces/Campaigns/CampaignDetails/ICampaignDetailData";
import {ICampaignId} from "../../interfaces/Campaigns/CampaignDetails/ICampaignId";
import NumberFormat from "react-number-format";
import formatStatusString from "../../services/utils/formatStatusString";
import getStatusColor from "../../services/utils/getStatusColor";

const CampaignDetails: React.FC = () =>{
    const [campaign, setCampaign] = useState<ICampaignDetailData>({} as ICampaignDetailData);

    const history = useHistory();
    const { params } = useRouteMatch<ICampaignId>();

    useEffect(() => {
        new CampaignsHttpClient().getCampaignInfo(params.id)
        .then(response => setCampaign(response.data.data))
        .catch(() => history.push('/list-campaigns'));
        
    }, [history, params.id]);

    
    return (
        <>
            <Header className='shadow-header'>
                <div className="left-side">
                    <Link to='list-campaigns'>{'<'}</Link> {campaign.name}
                </div>
                <div className='right-side'>
                    <span className="state-span">
                        <BsFillCircleFill size={10} fill={getStatusColor(campaign.state)} /> {formatStatusString(campaign.state)}
                    </span>
                    <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','} value={campaign.impressions} /> {campaign.impressions === 1 ? ' impressão' : ' impressões'}
                </div>
            </Header>

            <Container className='centered-container' >
                <AudienceDetail categories={campaign.segment?.categories} keywords={campaign.segment?.keywords} />

                <InvestmentDetail viewOnlyCpmMax={campaign.segment?.max_cpm} viewOnlyBudget={campaign.segment?.budget} />

                <CampaignCreatives creatives={campaign.creatives} />
            </Container>
        </>
    );
}

export default CampaignDetails;