import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;

    z-index: 999;
    background: #212121;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    div {
        background: #FFF;

        max-width: 834px;
        border-radius: 10px;

        h1 {
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            color: #585858;

            padding: 40px 124px;

            border-bottom: 1px solid #CFCFCF;
        }

        .toggle {
            position: relative;

            button {
                position: absolute;
                top: 40px;
                left: 61px;

                border: none;
                background: none;

                font-size: 18px;
                color: #C4C4C4;

                cursor: pointer;
            }
        }

        .creative-view {
            width: 800px;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 50px 0;

            img {
                max-width: 750px;
            }
        }

        p {
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: -0.011em;
            color: #7E7E7E;

            & + p {
                margin-top: 40px;
            }

        }
    }
`;