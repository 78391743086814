import React from "react";
import { Container, CampaignData } from './styles';
import { BsFillCircleFill } from 'react-icons/bs';
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import {IListCampaignsProps} from "../../../interfaces/Campaigns/ListCampaigns/IListCampaignsProps";
import formatStatusString from "../../../services/utils/formatStatusString";
import getStatusColor from "../../../services/utils/getStatusColor";

function DisplayCampaignsInfo({campaigns}: IListCampaignsProps) {
    return (
        <Container className='card-shadow'>
            <div className='title-div'>
                <h2>CAMPANHA</h2>
            </div>

            <CampaignData>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Status</th>
                            <th>Impressões Totais</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaigns.map(campaign => (
                            <tr key={campaign.id}>
                                <td>
                                    <Link to={`campaign-details/${campaign.id}`} className='default-link'>
                                        {campaign.name}
                                    </Link>
                                </td>
                                <td>
                                    <BsFillCircleFill size={10} fill={getStatusColor(campaign.state)} />
                                    {formatStatusString(campaign.state)}
                                </td>
                                <td>
                                    {campaign.impressions > 0 ?
                                        <span>
                                            <NumberFormat displayType="text" thousandSeparator={'.'} decimalSeparator={','}
                                                      value={campaign.impressions} />
                                            {campaign.impressions === 1 ? ' impressão' : ' impressões'}
                                        </span>
                                    : '--'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CampaignData>
        </Container>
    );
}


export default DisplayCampaignsInfo;