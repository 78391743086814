import React, {useCallback} from "react";
import {LoginInputContainer} from "./styles";

interface LoginInputProps {
    value: string;
    name: string;
    type: string;
    hasError: boolean;
    setHasError(newValue: boolean): void;
    setValue(value: string): void;
}

function LoginInput({value, setValue, name, type, hasError, setHasError}: LoginInputProps) {
    const validateInput = useCallback((newValue) => {
        setHasError(newValue.trim() === '');
    }, [setHasError]);

    return (
        <LoginInputContainer hasError={hasError}>
            <input  className="default-input" name={name} type={type}
                    onChange={(event) => setValue(event.target.value)}
                    onBlur={(event) => validateInput(event.target.value)} value={value} />
        </LoginInputContainer>
    )

}

export default LoginInput;
