import styled from "styled-components";

export const Container = styled.div`
    margin: 60px 0 210px;
`;

export const Header = styled.div`
    padding: 40px 0 20px 60px;
    border-bottom: 1px solid #EBEBEB;

    h2{
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        color: #256EF3;
    }
`

export const Phrase = styled.p`
    padding: 30px 0 0 60px;

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #8E8E8E;
`

export const Main = styled.div`
    padding: 34px 0 51px 75px;

    display: flex;
    align-items: center;
    gap: 69px;
`

export const Steps = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    .step{
        display: flex;
        align-items: center;

        & + .step{
            margin-top: 31px;
        }

        .circle{
            height: 20px;
            width: 20px;   
            border-radius: 50%;
            border: 1px solid #256EF3;
            color: #256EF3;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        p{
            margin-left: 16px;
            color: #494949;
        }
        
        .cross-line {
            color: #CFD2D9;
            text-decoration: line-through;
        }
    }

`

export const ContainerCheckEmailText = styled.div`
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;

    h4{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #585858;
        margin-bottom: 10px;
    }

    p{
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8E8E8E;
    }
`