import React, { ReactNode, useCallback, useState } from "react";
import { useNewCampaignValues } from "../../../../pages/NewCampaign/NewCampaignContext";
import Button from '../../../Generic/Button';
import { Container } from './styles';
import {CampaignsHttpClient} from "../../../../services/api/campaigns-http-client";
import {useHistory} from "react-router-dom";
import ErrorBox from "../../../Generic/ErrorBox";

interface NewCampaignButtonProps {
    setShowModal(value: boolean): void;
    children: ReactNode;
}

function NewCampaignButton({ children, setShowModal }: NewCampaignButtonProps) {
  const history = useHistory();

  const [showErrorBox, setShowErrorBox] = useState(false);

  const { campaignName, keyword1, keyword2, keyword3, keyword4, budget, cpmMax, categoriesIDs } = useNewCampaignValues();

  const AllKeywordsAreEmpty = (keyword1 === '' && keyword2 === '' && keyword3 === '' && keyword4 === '');

  const executeCreateCampaignRequest = useCallback(() => {
    new CampaignsHttpClient().createCampaign(campaignName, budget, cpmMax,
            [keyword1, keyword2, keyword3, keyword4], categoriesIDs
    ).then(response => setShowModal(true)).catch(() => {history.push('/create-campaigns')});
  }, [history, campaignName, keyword1, keyword2, keyword3, keyword4, budget, cpmMax, categoriesIDs, setShowModal]);

  const handleFormSubmit = useCallback(() => {
    if (categoriesIDs.length === 0 && AllKeywordsAreEmpty) {
      setShowErrorBox(true);
    } else if (budget < 0.01 || cpmMax < 0.01 || campaignName === '') {
      setShowErrorBox(true);
    } else {
      setShowErrorBox(false);
      executeCreateCampaignRequest();
    }
  }, [campaignName, budget, cpmMax, categoriesIDs, AllKeywordsAreEmpty, executeCreateCampaignRequest]);

  return(
    <Container>
      <Button execute={handleFormSubmit}>{children}</Button>
      {showErrorBox &&
        (  
          <div className="error-box">
            <ErrorBox showBox={showErrorBox} setShowBox={setShowErrorBox} >
              Encontramos um ou mais campos sem preenchimento, apenas a opção de Keywords não é obrigatória, todos os demais campos devem ser preenchidos e ao menos 1 categoria selecionada. 
            </ErrorBox>
          </div>
        )
      }
    </Container>
  );
}

export default NewCampaignButton;