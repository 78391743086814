import styled from 'styled-components';

export const Header  = styled.div`
    padding: 26px 0 26px 65px;

    h1 {
        color: #585858;
        font-weight: 300;
        font-size: 30px;
        line-height: 35px;
    }
`;

export const Container = styled.div`
    button {
      margin: 40px 0 114px;
    }
`;
