import styled from "styled-components";

export const SubmitButton = styled.button`
    padding: 14px 28px;
    
    background: #256EF3;
    border: none;

    font-weight: 600;
    color: #FFF;

    &:hover {
        background: #5591FF;
        cursor: pointer;
    }
`;