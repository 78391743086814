import React from "react";
import {CarrouselContainer} from "../../../pages/Authenticate/styles";
import AliceCarousel from "react-alice-carousel";
import CarrouselImage from "./CarrouselImage";

const InfoCarrousel: React.FC = () => {
    const items = [
        <CarrouselImage key="1" src="carrousel-1.png" alt="Carrousel 1"
                        title="Encontre o público certo <br />de forma simples"
                        text="Impacte milhares de pessoas envolvidas com o<br /> mercado de tecnologia de forma
                            assertiva e eficiente <br />com uso de dados e programática." />,
        <CarrouselImage key="2" src="carrousel-2.png" alt="Carrousel 2"
                        title="Descubra com quais assuntos<br /> seu público mais interage"
                        text="Usando dados e IA para mapear padrões de<br /> comportamento, descubra quais são aqueles
                            usuários<br /> com maior probabilidade de interagir com seu negócio." />,
        <CarrouselImage key="3" src="carrousel-3.png" alt="Carrousel 3"
                        title="Impacte seu público<br /> onde ele estiver"
                        text="Seja em sites e apps, mostre sua marca no<br /> momento e na frequência certa, aumentando
                            a<br/> probabilidade de engajamento dos usuários" />
    ];

    return (
        <CarrouselContainer>
            <AliceCarousel disableButtonsControls controlsStrategy="responsive" mouseTracking items={items} />
        </CarrouselContainer>
    )
}

export default InfoCarrousel;