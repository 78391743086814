import React from 'react';
import { useNewCampaignValues } from '../../../../pages/NewCampaign/NewCampaignContext';
import { Container } from './styles';

const GeneralDetail: React.FC = () => {
    const { campaignName, setCampaignName } = useNewCampaignValues();

    return (
        <Container className='card-shadow'>
            <h2>Geral</h2>
            <div>
                <span>Nome da <br /> campanha</span>
                <input className='default-input' type="text" placeholder="Campanha" onChange={(event) => setCampaignName(event.target.value)} value={campaignName} />
            </div>
        </Container>
    );
}

export default GeneralDetail;