import styled from "styled-components";

export const Container = styled.div`
    margin-top: 63px;

    padding: 37px 0 86px;

    h2 {
        font-weight: 300;
        color: #256EF3;

        margin: 0 0 20px 60px;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .categorias {
        display: flex;

        border-top: 1px solid #EBEBEB;
        border-bottom: 1px solid #EBEBEB;
        padding-left: 60px;

        span {
            border-right: 1px solid #EBEBEB;
            padding: 30px 47px 0 0;
            margin-right: 50px;
        }

    }

    .keywords {
        display: flex;
        align-items: center;

        padding: 48px 60px 0;

        .keywords-description {
            display: flex;
            flex-direction: column;

            margin-right: 40px;

            span {
                display: flex;
                align-items: center;
            }
            
            p {
                font-size: 12px;
                line-height: 14px;
                color: #8E8E8E;
    
                max-width: 117px;
            }
        }


        .keywords-inputs {
            display: flex;
            flex-wrap: wrap;

            
            div {
                width: 283px;
                height: 47px;
            }
            
            div:nth-of-type(2n+1) {
                margin-right: 6%;
            }

            div:nth-of-type(3),  div:nth-of-type(4){
                margin-top: 30px;
            }
        }
    }
`;