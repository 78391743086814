import styled from "styled-components";

export const Container = styled.div`
    margin-top: 57px;

    padding: 40px 0 60px;

    h2 {
        font-weight: 300;
        color: #256EF3;

        margin: 0 0 20px 60px;
    }

    .border-top {
        border-top: 1px solid #EBEBEB;
        padding: 30px 60px 0;
    }

    div {

        & + div {
            margin-top: 60px;
            padding: 0 60px;
        }

        div {
            flex: 1;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;

        
    }

    .budget-span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        margin-right: 35px;
        color: #585858
    }

    .help-span {
        display: flex;
        align-items: center;

        margin-right: 20px;
        
        svg {
            margin-left: 4px;
            color: #FFF;
            fill: #8E8E8E;
        }
    }
`;