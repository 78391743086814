import styled from "styled-components";

export const Container = styled.div`
    padding: 40px 0 60px;

    h2 {
        font-weight: 300;
        color: #256EF3;

        margin: 0 0 20px 60px;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        border-top: 1px solid #EBEBEB;
        padding: 30px 60px 0;

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;

            margin-right: 35px;
        }

    }
`;