import styled from "styled-components";

export const BaseContainer = styled.div`
display: flex;
  & {
    height: 100%;
  }
`;

export const Footer = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #9C9C9C;

  text-align: right;
  padding-right: 30px;

  margin: 40px 0 0 !important;

  span {
    color: #256EF3;
    text-decoration: underline;
  }
`;

export const ContainerAuth = styled.div` 

  background: #FFF;

  & {
    min-height: 100vh;
    flex: 10;
    padding: 81px 0 0 188px;
    width: 55%;
  }
  
  header {
    margin-bottom: 20%;
  }
  
  
  label, h1 {
    color: #585858;
    font-weight: 600;
  }
  
  section a {
    margin-left: 255px;
  }
  
  h1 {
    font-size: 24px;
  }
  
  p {
    color: #9C9C9C;
    font-size: 14px;
    margin: 9px 0 64px 0;
  }
  
  .error-box {
    margin-top: 30px;
    width: 571px;
    white-space: pre-wrap;
  }
`;

export const CarrouselContainer = styled.div`
    & {
      flex: 9.3939;
      width: 45%;
      min-height: 100vh;
      background: #F3F7FF;

      display: flex;
      align-items: center;
      justify-content: center;

      .alice-carousel {
        padding-top: 93px;
      }

      .alice-carousel__dots-item.__active {
        background-color: #256EF3;
        border-radius: 10px;
        height: 10px;
        width: 24px;
      }
      
      .alice-carousel__dots-item {
        background: #CCDEFF;
        height: 10px;
        width: 10px;
      }

      .alice-carousel__dots-item:hover {
        background: #CCDEFF;
      }

      .alice-carousel__dots-item.__active:hover {
        background: #256EF3;
      }
      
      .alice-carousel__wrapper {

        .text-carrousel {
          width: 541px;
          padding-top: 60px;
          margin: 0 auto 60px;
        }
        
        .img-carrousel {
          width: 100%;
          display: flex;
          justify-content: center;
          
          img {
            max-width: 500px;
            max-height: 400px;
          }
        }
      }
      
      h1 {
        color: #256EF3;
        text-align: center;
      }
      
      p {
        color: #899FC7;
        font-size: 14px;
        padding-top: 20px;
        text-align: center;
      }
    }
`;