import React, { useCallback, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai'
import { useNewCampaignValues } from '../../../../../pages/NewCampaign/NewCampaignContext';
import { Container } from './styles';

interface categoryInformation {
    title: string;
    description: string;
    id: number;
    viewOnly: boolean;
}

function Category({title, description, id, viewOnly}: categoryInformation) {
  const [checked, setChecked] = useState(viewOnly);

  const {categoriesIDs, setCategoriesIDs } = useNewCampaignValues();

  const handleCheckboxClick = useCallback((id) => {
    if (viewOnly) return;

    if(checked) {
      let updatedCategoriesIDs = categoriesIDs.filter(categoryID => categoryID !== id);
      setCategoriesIDs(updatedCategoriesIDs);
    } else {
      setCategoriesIDs([...categoriesIDs, id]);
    }
    
    setChecked(!checked);

  }, [viewOnly, setChecked, checked, setCategoriesIDs, categoriesIDs]);

  return (
    <Container viewOnly={viewOnly}>
      <div onClick={() => viewOnly ? '' : handleCheckboxClick(id)}>
        {checked ? (
          <div className="checked-checkbox">
            <AiOutlineCheck size={20} />
          </div>
          ) : (
          <div className="unchecked-checkbox"></div>
        )}
      </div>

      <div>
          <h3>{title}</h3>
          <p>{description}</p>
      </div>
    </Container>
  );
}

export default Category;