import React from 'react';
import {Switch, Route, HashRouter} from 'react-router-dom';
import NewCampaign from '../pages/NewCampaign';
import Authenticate from "../pages/Authenticate";
import CampaignStatus from '../pages/ListCampaigns';
import CampaignDetails from '../pages/CampaignDetails';
import PublicRoute from "../services/auth/PublicRoute";
import PrivateRoute from "../services/auth/PrivateRoute";

const Routes: React.FC = () => (
    <HashRouter>
        <Switch>
            <PrivateRoute path="/create-campaigns" component={NewCampaign} />
            <PrivateRoute path="/list-campaigns" component={CampaignStatus} />
            <Route path="/campaign-details/:id" component={CampaignDetails} />
            <PublicRoute path="/" component={Authenticate} />
        </Switch>
    </HashRouter>
)

export default Routes;