import styled from "styled-components";

export const Container = styled.div`

`;

export const Header = styled.div`
    padding: 9px 64px;

    display: flex;
    align-items: center;

    img {
        margin-right: 54px;
    }

    div {
        height: 50px;
        padding-left: 50px;
        
        border-left: 1px solid #CBCBCB;
        display: flex;
        align-items: center;
    }

    h1 {
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        color: #585858;
    }
`